import React from 'react';
import { LabelHTMLAttributes, DetailedHTMLProps } from 'react';
import cn from 'classnames';

import './Button.css';

interface ButtonLabelProps
  extends DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  children: React.ReactNode;
  handleClick?: () => void;
  appearance: 'primary' | 'secondary';
}

const ButtonLabel: React.FC<ButtonLabelProps> = ({
  children,
  handleClick,
  appearance,
  ...props
}) => {
  return (
    <label
      className={cn('Button', {
        primary: appearance === 'primary',
        secondary: appearance === 'secondary',
      })}
      {...props}
    >
      {children}
    </label>
  );
};

export default ButtonLabel;
