// src/pages/LoginPage.tsx
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AuthForm from '../components/Auth/AuthForm';
import Welcome from '../components/Auth/Welcome';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (!loading && user) {
      navigate('/editor'); // Redirect to home page if user is logged in
    }
  }, [loading, user, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="login-container">
      <div className="login-box">
        {user ? (
          <Welcome user={user} onLogout={() => setUser(null)} />
        ) : (
          <AuthForm onLogin={(user) => setUser(user)} />
        )}
      </div>
    </div>
  );
};

export default LoginPage;
