import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';

import useAuth from '../../hooks/useAuth';
import Welcome from '../Auth/Welcome';
import MenuLink from '../MenuLink/MenuLink';

import './Header.css';
import { auth, firestore } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const { Header: AntHeader } = Layout;

const Header: React.FC = () => {
  const { user } = useAuth();
  const [role, setRole] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(firestore, 'users', user.uid));
        if (userDoc.exists()) {
          setRole(userDoc.data().role);
        }
      }
    };

    fetchUserRole();
  }, [auth, role]);

  return (
    <AntHeader className="App-header">
      <nav className="nav-bar">
        {user && (
          <ul className="nav-links">
            {(role === 'admin' || role === 'editor') && (
              <li>
                <MenuLink href="/editor" title="Editor" />
              </li>
            )}
            <li>
              <MenuLink href="/viewer" title="Viewer" />
            </li>
          </ul>
        )}
        <div className="welcome-component">
          {user ? <Welcome user={user} /> : <Link to="/auth">Login</Link>}
        </div>
      </nav>
    </AntHeader>
  );
};

export default Header;
