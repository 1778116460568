import React from 'react';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import cn from 'classnames';

import './Button.css';

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
  handleClick?: () => void;
  appearance: 'primary' | 'secondary';
}

const Button: React.FC<ButtonProps> = ({
  children,
  handleClick,
  appearance,
  ...props
}) => {
  return (
    <button
      className={cn('Button', {
        primary: appearance === 'primary',
        secondary: appearance === 'secondary',
      })}
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
