// src/components/Auth/Welcome.tsx
import React from 'react';
import { auth } from '../../firebaseConfig';
import { Button } from 'antd';
import './Welcome.css';

const Welcome: React.FC<{ user: any; onLogout?: () => void }> = ({
  user,
  onLogout,
}) => {
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      onLogout && onLogout();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="welcome-container">
      <span>Welcome, {user.email}</span>
      <Button type="primary" onClick={handleSignOut}>
        Sign Out
      </Button>
    </div>
  );
};

export default Welcome;
