import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
import LoginPage from './pages/LoginPage';
import Viewer from './pages/Viewer';
import Editor from './pages/Editor';
import useAuth from './hooks/useAuth';
import Header from './components/Header/Header';
import './App.css';

const { Content } = Layout;

const App: React.FC = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Layout style={{ height: '100vh' }}>
        <Header />
        <Content>
          <Routes>
            <Route path="/auth" element={<LoginPage />} />
            <Route path="/viewer" element={<Viewer />} />
            <Route path="/editor" element={<Editor />} />
            <Route path="/" element={user ? <Viewer /> : <LoginPage />} />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
};

export default App;
