import React, { useState, useEffect } from 'react';
import { ResizableBox } from 'react-resizable';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import Player from '../components/PlayerViewer/Player';
import GMap from '../components/GMapViewer/GMap';
import Table from '../components/Table/Table';
import { VideoInfo, videoListState } from '../atoms/video';
import useAuth from '../hooks/useAuth';

import 'react-resizable/css/styles.css';
import './Viewer.css';

const Viewer: React.FC = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [currentSegment, setCurrentSegment] = useState<VideoInfo | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<string>('');
  const [segmentStartTime, setSegmentStartTime] = useState<string>('');
  const [segmentEndTime, setSegmentEndTime] = useState<string>(''); // Added this state

  const [playerHeight, setPlayerHeight] = useState<number>(() => {
    const saved = localStorage.getItem('playerHeight');
    return saved ? JSON.parse(saved) : 0.7;
  });
  const [mapHeight, setMapHeight] = useState<number>(() => {
    const saved = localStorage.getItem('mapHeight');
    return saved ? JSON.parse(saved) : 0.7;
  });
  const [tableHeight, setTableHeight] = useState<number>(() => {
    const saved = localStorage.getItem('tableHeight');
    return saved ? JSON.parse(saved) : 200;
  });
  const [playerWidth, setPlayerWidth] = useState<number>(() => {
    const saved = localStorage.getItem('playerWidth');
    return saved ? JSON.parse(saved) : 0.5;
  });
  const [mapWidth, setMapWidth] = useState<number>(() => {
    const saved = localStorage.getItem('mapWidth');
    return saved ? JSON.parse(saved) : 0.5;
  });

  useEffect(() => {
    localStorage.setItem('playerHeight', JSON.stringify(playerHeight));
  }, [playerHeight]);

  useEffect(() => {
    localStorage.setItem('mapHeight', JSON.stringify(mapHeight));
  }, [mapHeight]);

  useEffect(() => {
    localStorage.setItem('tableHeight', JSON.stringify(tableHeight));
  }, [tableHeight]);

  useEffect(() => {
    localStorage.setItem('playerWidth', JSON.stringify(playerWidth));
  }, [playerWidth]);

  useEffect(() => {
    localStorage.setItem('mapWidth', JSON.stringify(mapWidth));
  }, [mapWidth]);

  const handleMarkerClick = (segment: VideoInfo) => {
    setSelectedVideo(segment.fileName);
    setSegmentStartTime(segment.startTime);
    setSegmentEndTime(segment.endTime);
    setCurrentSegment(segment);
  };

  const handleRowClick = (segment: VideoInfo) => {
    handleMarkerClick(segment);
  };

  const handleSegmentEnd = (nextSegmentIndex: number) => {
    console.log('Segment ended, playing next segment:', nextSegmentIndex); // Debugging log
    if (nextSegmentIndex < videoList.length) {
      const nextSegment = videoList[nextSegmentIndex];
      setSelectedVideo(nextSegment.fileName);
      setSegmentStartTime(nextSegment.startTime);
      setSegmentEndTime(nextSegment.endTime);
      setCurrentSegment(nextSegment);
    }
  };

  useEffect(() => {
    if (!loading && !user) {
      navigate('/auth');
    }
  }, [loading, user, navigate]);

  return (
    <div style={{ height: '100%' }}>
      <div style={{ display: 'flex', height: `calc(100% - ${tableHeight}px)` }}>
        <ResizableBox
          width={playerWidth * window.innerWidth}
          height={window.innerHeight - tableHeight}
          axis="both"
          resizeHandles={['e']}
          minConstraints={[100, 100]}
          onResizeStop={(e, data) => {
            setPlayerWidth(data.size.width / window.innerWidth);
          }}
          style={{ flexGrow: playerWidth }}
        >
          <Player
            selectedVideo={selectedVideo}
            segmentStartTime={segmentStartTime}
            segmentEndTime={segmentEndTime} // Pass segmentEndTime to Player
            onSegmentEnd={handleSegmentEnd}
            segmentTitle={currentSegment?.title}
            segmentFileName={currentSegment?.fileName}
          />
        </ResizableBox>
        <ResizableBox
          width={mapWidth * window.innerWidth}
          height={window.innerHeight - tableHeight}
          axis="both"
          resizeHandles={['w']}
          minConstraints={[100, 100]}
          onResizeStop={(e, data) => {
            setMapWidth(data.size.width / window.innerWidth);
          }}
          style={{ flexGrow: mapWidth }}
        >
          <GMap
            handleMarkerClick={handleMarkerClick}
            activeSegment={currentSegment}
          />
        </ResizableBox>
      </div>
      <ResizableBox
        height={tableHeight}
        width={Infinity}
        axis="y"
        resizeHandles={['s', 'n']}
        minConstraints={[Infinity, 100]}
        onResizeStop={(e, data) => setTableHeight(data.size.height)}
      >
        <div
          style={{
            width: '100%',
            height: `${tableHeight}px`,
            overflow: 'auto',
          }}
        >
          <Table
            activeSegment={currentSegment}
            onRowClick={handleRowClick}
            isEditable
          />
        </div>
      </ResizableBox>
    </div>
  );
};

export default Viewer;
