import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import './GMap.css';
import { filteredVideoListState, VideoInfo } from '../../atoms/video';
import { useRecoilValue } from 'recoil';
import { loadFiles } from '../../utils/kmXLoadUtils';
import { parse } from '../../utils/kmXParseUtils';
import ButtonLabel from '../Button/ButtonLabel';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 49.024502,
  lng: 31.419201,
};

interface GMapProps {
  handleMarkerClick?: (segment: VideoInfo) => void;
  activeSegment?: VideoInfo | null;
}

interface LatLng {
  lat: number;
  lng: number;
}

const GMap: React.FC<GMapProps> = ({ handleMarkerClick, activeSegment }) => {
  const videoList = useRecoilValue(filteredVideoListState);
  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(
    null,
  );
  const [markerPosition, setMarkerPosition] = useState<LatLng | undefined>();
  const [, setSelectedSegment] = useState<VideoInfo | null>(null);
  const dataLayerRef = useRef<google.maps.Data.Feature[]>([]);
  const dataLayerClickRef = useRef<google.maps.MapsEventListener | null>();
  const [polineRef, setPolineRef] = useState<google.maps.Polyline | null>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  const handleImportKmz = async (e: ChangeEvent<HTMLInputElement>) => {
    const kmzFiles = Array.from((e.target as HTMLInputElement)?.files ?? []);

    if (kmzFiles?.length) {
      const filesData = await loadFiles(kmzFiles);

      const geoJSONList = [];
      let allIcons: Record<string, string> = {};
      for (const [kmzFileName, kmzData] of filesData) {
        const geoJSON = await parse(kmzData, { name: kmzFileName, icons: {} });
        geoJSONList.push(geoJSON);
        allIcons = { ...allIcons, ...(geoJSON as any).properties.icons };
      }

      // geojson to google map's dataLayer
      if (mapRef) {
        // cleanup previous layers first
        dataLayerRef.current.forEach((layer) => {
          mapRef.data.remove(layer);
        });

        // Set the icon from the "icon" property in the geoJson
        mapRef.data.setStyle((feature) => {
          let icon;
          if (feature.getProperty('icon')) {
            const iconName = feature.getProperty('icon') as string;
            icon = allIcons[iconName];
          }
          return { icon };
        });

        dataLayerRef.current = geoJSONList
          .map((geoJSON) => {
            return mapRef.data.addGeoJson(geoJSON);
          })
          .flat();

        google.maps.event.removeListener(dataLayerClickRef.current!);
        dataLayerClickRef.current = mapRef.data.addListener(
          'click',
          (event: any) => {
            if (!infoWindow) return;

            const feature = event.feature;
            let description = feature.getProperty('description');
            if (description && description['@type'] === 'html') {
              description = description.value;
              // TODO: once more dirty image hack
              description = description.replace(
                /(<img[^>]+?src=")([^"]+)("[^>]+>)/g,
                (
                  match: string,
                  startTag: string,
                  iconName: string,
                  entTag: string,
                ) => {
                  const iconSrc = allIcons[iconName];
                  return `${startTag}${iconSrc}${entTag}`;
                },
              );
            }
            let html =
              '<b>' + feature.getProperty('name') + '</b><br>' + description;
            if (feature.getProperty('link')) {
              html +=
                "<br><a class='normal_link' target='_blank' href='" +
                feature.getProperty('link') +
                "'>link</a>";
            }
            infoWindow.setContent(html);
            infoWindow.setPosition(event.latLng);
            infoWindow.setOptions({
              pixelOffset: new google.maps.Size(0, -34),
            });
            infoWindow.open(mapRef);
          },
        );
      }
    }
  };

  useEffect(() => {
    return () => {
      if (mapRef) {
        // cleanup listeners on unmount
        google.maps.event.removeListener(dataLayerClickRef.current!);
      }
    };
  }, [mapRef]);

  useEffect(() => {
    if (mapRef && videoList.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      videoList.forEach((segment) => {
        if (segment.location) {
          bounds.extend(
            new google.maps.LatLng(segment.location.lat, segment.location.lng),
          );
        }
      });
      mapRef.fitBounds(bounds);
    }
  }, [mapRef, videoList]);

  useEffect(() => {
    if (activeSegment && activeSegment.location) {
      setMarkerPosition(activeSegment.location);
    }
  }, [activeSegment]);

  const handleMarkerClickInternal = (segment: VideoInfo) => {
    setSelectedSegment(segment);
    setMarkerPosition(segment.location);
    if (handleMarkerClick) {
      handleMarkerClick(segment);
    }
  };

  const getPathCoordinates = (fileName: string): LatLng[] => {
    return videoList
      .filter((segment) => segment.fileName === fileName && segment.location)
      .map((segment) => segment.location as LatLng);
  };

  const uniqueVideos = Array.from(
    new Set(videoList.map((segment) => segment.fileName)),
  );

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
    >
      <ButtonLabel appearance="primary" htmlFor="kmzImport">
        <input
          id={'kmzImport'}
          type="file"
          accept=".kmz,.kml"
          multiple
          onChange={handleImportKmz}
          style={{ display: 'none' }}
        />
        Import KMZs
      </ButtonLabel>

      <GoogleMap
        mapContainerStyle={containerStyle}
        options={{
          gestureHandling: 'greedy',
          mapTypeId: 'hybrid',
          zoom: mapRef?.getZoom() || 6,
        }}
        onLoad={(map) => {
          setMapRef(map);
          setInfoWindow(new google.maps.InfoWindow());
          map.setCenter(
            activeSegment ? activeSegment.location : markerPosition || center,
          );
        }}
      >
        {uniqueVideos.map((video, index) => (
          <Polyline
            key={index}
            path={getPathCoordinates(video)}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 1.0,
              strokeWeight: 2,
              visible: activeSegment?.fileName === video,
              icons: [
                {
                  icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                  offset: '100%',
                  repeat: '30%',
                },
              ],
            }}
            onLoad={(polyline) => {
              setPolineRef(polyline);
            }}
          />
        ))}
        {videoList.map(
          (segment, index) =>
            segment.location && (
              <Marker
                key={index}
                position={segment.location}
                onClick={() => handleMarkerClickInternal(segment)}
                icon={{
                  url:
                    activeSegment && activeSegment.fileName === segment.fileName
                      ? activeSegment === segment
                        ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                        : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                      : 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                }}
              />
            ),
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default GMap;
